
import {computed, defineComponent, PropType} from 'vue';
import useIcon from '@/components/icon/icon';

export default defineComponent({
  name: 'MenuItemIcon',
  props: {
    item: {
      type: Object as PropType<MenuItem>,
    },
    size: {
      type: String as PropType<IconSize>,
      default: 'mini',
    }
  },
  setup(props: MenuItemIconProps) {
    const {
      getFontSize,
    } = useIcon();

    const fontSize = computed(() => {
      const {size} = props as MenuItemIconProps;
      return getFontSize(size);
    });

    return {
      fontSize,
    };
  },
});
