
import {defineComponent, onMounted, PropType, ref, watch} from 'vue';

export default defineComponent({
  name: 'ColorPicker',
  props: {
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    predefine: {
      type: Array as PropType<string[]>,
    },
    showAlpha: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:model-value',
    'change',
  ],
  setup(props: ColorPickerProps, {emit}) {
    const internalValue = ref<string>();

    watch(() => props.modelValue, () => {
      internalValue.value = props.modelValue;
    });

    const onChange = (value: string) => {
      emit('update:model-value', value);
      emit('change', value);
    };

    onMounted(() => {
      const {modelValue} = props;
      internalValue.value = modelValue;
    });

    return {
      internalValue,
      onChange,
    };
  },
});
