
import {computed, defineComponent, inject, onMounted, PropType, readonly, ref, watch} from 'vue';
import {ElInput} from 'element-plus';
import {plainClone} from '@/utils/object';
import useTagService from '@/services/tag/tagService';
import {useStore} from 'vuex';
import {FILTER_OP_CONTAINS, FILTER_OP_EQUAL} from '@/constants/filter';
import {getNewTag} from '@/components/tag/tag';
import {getPredefinedColors} from '@/utils/color';
import ColorPicker from '@/components/color/ColorPicker.vue';

export default defineComponent({
  name: 'TagInputItem',
  components: {
    ColorPicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<Tag>,
    },
    placeholder: {
      type: String,
    },
    size: {
      type: String as PropType<BasicSize>,
      default: 'mini',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'update:model-value',
    'input',
    'click',
    'blur',
    'focus',
    'keyup.enter',
    'close',
    'check',
    'delete',
  ],
  setup(props: TagInputItemProps, {emit}) {
    const store = useStore();

    const internalValue = ref<Tag>(getNewTag());

    const isFocus = ref<boolean>(false);

    const inputRef = ref<typeof ElInput>();

    const isNew = computed<boolean>(() => !internalValue.value._id);

    // predefined colors
    const predefinedColors = readonly<string[]>(getPredefinedColors());

    watch(() => props.modelValue, () => {
      if (!props.modelValue) {
        internalValue.value = getNewTag();
      } else {
        internalValue.value = plainClone(props.modelValue);
      }
    });

    const isDisabled = (key: string) => {
      switch (key) {
        case 'check':
          return !internalValue.value.name;
        case 'close':
          return false;
        case 'delete':
          return false;
        default:
          return false;
      }
    };

    const onInput = (name: string) => {
      const value = {...props.modelValue, name};
      emit('input', value);
    };

    const onClick = () => {
      emit('click');
    };

    const onBlur = () => {
      isFocus.value = false;
      emit('blur');
    };

    const onFocus = () => {
      isFocus.value = true;
      emit('focus');
    };

    const focus = () => {
      inputRef.value?.focus();
    };

    const onSelect = (value: Tag) => {
      internalValue.value = value;
    };

    const onCheck = () => {
      if (isDisabled('check')) return;
      emit('update:model-value', internalValue.value);
      emit('check', internalValue.value);
    };

    const onClose = () => {
      if (isDisabled('close')) return;
      emit('close');
    };

    const onDelete = () => {
      if (isDisabled('delete')) return;
      emit('delete');
    };

    const ctx = inject<ListStoreContext<BaseModel>>('store-context');

    const fetchSuggestions = async (queryString: string, callback: (data: Tag[]) => void) => {
      const {
        getList,
      } = useTagService(store);
      const params = {
        page: 1,
        size: 50,
        conditions: [
          {key: 'col', op: FILTER_OP_EQUAL, value: `${ctx?.namespace}s`}
        ]
      } as ListRequestParams;
      if (queryString) {
        const conditions = params.conditions as FilterConditionData[];
        conditions.push({key: 'name', op: FILTER_OP_CONTAINS, value: queryString});
      }
      try {
        const res = await getList(params);
        return callback(res.data || []);
      } catch (e) {
        console.error(e);
        callback([]);
      }
    };

    onMounted(() => {
      if (!props.modelValue) {
        internalValue.value = getNewTag();
      } else {
        internalValue.value = plainClone(props.modelValue);
      }
    });

    return {
      predefinedColors,
      internalValue,
      isFocus,
      inputRef,
      isNew,
      onClick,
      onInput,
      onBlur,
      onFocus,
      focus,
      onCheck,
      onClose,
      onDelete,
      onSelect,
      isDisabled,
      fetchSuggestions,
    };
  },
});
