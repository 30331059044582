import { render } from "./TagInputItem.vue?vue&type=template&id=3fc2eba4&scoped=true"
import script from "./TagInputItem.vue?vue&type=script&lang=ts"
export * from "./TagInputItem.vue?vue&type=script&lang=ts"

import "./TagInputItem.vue?vue&type=style&index=0&id=3fc2eba4&lang=scss&scoped=true"
import "./TagInputItem.vue?vue&type=style&index=1&id=3fc2eba4&scoped=true&lang=css"
import "./TagInputItem.vue?vue&type=style&index=2&id=3fc2eba4&lang=css"
script.render = render
script.__scopeId = "data-v-3fc2eba4"

export default script